import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { DiscussionEmbed } from 'disqus-react';

import ContentWrapper from '../components/contentWrapper';
import facebookPath from '../utils/facebookShareBuilder';
import Layout from '../components/layout';
import menuPaths from '../constants/menuPaths';
import twitterPath from '../utils/twitterShareBuilder';
import { pathBuilder, assetPathBuilder } from '../utils/pathBuilder';

import { ReactComponent as FacebookIcon } from '../images/svgs/facebook-logo.svg';
import { ReactComponent as TwitterIcon } from '../images/svgs/twitter-logo.svg';
import styles from '../styles/templates/blogTemplate.module.scss';

export default function Template({
  data, location,
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;

  const pageUrl = pathBuilder(frontmatter.language, frontmatter.date, frontmatter.title);
  const imageUrl = assetPathBuilder(frontmatter.image);
  const parsedHtml = html.split('src="assets/').join('src="/assets/');


  const contactUsPath = `/${frontmatter.language}/${menuPaths.contactUs[frontmatter.language]}`;
  const date = Date.parse(frontmatter.date);

  const shareOnFacebook = facebookPath(
    frontmatter.language,
    frontmatter.date,
    frontmatter.title,
  );

  const shareOnTwitter = twitterPath(
    frontmatter.language,
    frontmatter.date,
    frontmatter.title,
    frontmatter.category,
  );

  const disqusShortname = 'blog-msfint-com';

  const disqusConfig = {
    url: `https://blog.msfint.com${pageUrl}`,
    identifier: frontmatter.id,
    title: frontmatter.title,
    language: frontmatter.language,
  };

  return (
    <Layout location={location}>
      <ContentWrapper>
        <Helmet>
          <title>{frontmatter.title}</title>
          <meta name="description" content={frontmatter.previewText} />
          <meta property="og:title" content={frontmatter.title} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={`https://blog.msfint.com${pageUrl}`} />
          <meta property="og:image" content={`https://blog.msfint.com/${frontmatter.image}`} />
          <meta property="og:description" content={frontmatter.previewText} />
          <meta property="article:published_time" content={(new Date(frontmatter.date)).toISOString()} />
        </Helmet>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.shareIcons}>
              <a href={shareOnFacebook} className={styles.facebook} >
                <FacebookIcon />
              </a>
              <a href={shareOnTwitter} className={styles.twitter} >
                <TwitterIcon />
              </a>
            </div>
            <span className={styles.date}>
              <FormattedDate
                value={date}
                year="numeric"
                month="long"
                day="2-digit"
              />
            </span>
          </div>
          <h1 className={styles.title}>{frontmatter.title}</h1>
          <div className={styles.author}>
            <FormattedMessage id="article.author" />
            {frontmatter.author}
          </div>
          <p className={styles.previewText}>
            {frontmatter.previewText}
          </p>
          <img src={imageUrl} alt="article preview" />
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: parsedHtml }}
          />
          <div className={styles.contributeContainer}>
            <FormattedMessage id="article.contribute" />
            <Link to={contactUsPath} className={styles.link}>
              <FormattedMessage
                id="menu.contribute"
              />
            </Link>
          </div>
          <div className={styles.comments}>
            <FormattedMessage
              id="template.comments"
            />
            <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
          </div>
        </div>
      </ContentWrapper>
    </Layout>
  );
}

Template.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query BlogPostByPath($mdPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $mdPath } }) {
      html
      frontmatter {
        category
        date
        author
        path
        title
        image
        language,
        previewText
      }
    }
  }
`;
